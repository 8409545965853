
// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';

// APIs
import { BlockHourInvoiceDetailsRequest } from '../../../requests';

// Utils
import { auth, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone, formatPrice } from '../../../utils';
import { Save, TableLite, History } from '../../../components';

function BlockHourInvoicesView() {
  const { invoiceId } = useParams();

  const INITIAL_FORM = {
    approve_request_id: '',
    contract_id: '',
    status: 'Draft',
    request_id: '',
    block_hour_invoice_item: []
  };

  const headers = ["arrival_to_airport_iata", "block_hours", "unit_cost", "total_cost", "vat_percentage"];

  const CustomHeaders = {
    "arrival_to_airport_iata": "Leg",
    "block_hours": "Block Hours",
    "unit_cost": "Unit Cost",
    "total_cost": "Total Cost",
    "vat_percentage": "VAT %",
  };

  const [isLoading, _isLoading] = useState(invoiceId ? true : false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);

  useEffect(() => {
    if (invoiceId && authState) {
      getBlockHourInvoiceDetails();
    }
  }, [authState, invoiceId]);

  const getBlockHourInvoiceDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    BlockHourInvoiceDetailsRequest(token, invoiceId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        let block_hour_invoice_item = data?.block_hour_invoice_item || [];
        block_hour_invoice_item = block_hour_invoice_item?.map((item, index) => (
          {
            ...item,
            temp_id: `temp_${index}`,
            unit_cost: Number(item?.unit_cost).toFixed(3),
            total_cost: Number(item?.total_cost).toFixed(3),
            vat_amount: Number(item?.vat_amount).toFixed(3),
            block_hours: Number(item?.block_hours).toFixed(2),
          }
        ))?.sort((a, b) => a?.approve_request_leg > b?.approve_request_leg ? 0 : 1);
        const newData = {
          ...data,
          block_hour_invoice_item
        };
        // console.log(data);
        _isLoading(false);
        _form(newData);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch invoice details' });
        }
      )
  };


  // *********** Handlers ***********

  const handleDownloadPdf = () => {
    const fileName = `invoice_${invoiceId}.pdf`;
    // create a wrapper div for content and set it's width
    const wrapper = document.createElement('div');
    wrapper.style.width = '1020px';
    wrapper.style.margin = '0px';
    // create a duplicate of the content div which is to be printed
    let sourceDiv = document.querySelector('.request-block');
    sourceDiv = sourceDiv.cloneNode(true);
    sourceDiv.style.padding = '0px';
    wrapper.appendChild(sourceDiv);
    let doc = new jsPDF('p', 'px', [650, 1500]);
    // create water mark
    for (let i = 1; i <= 2; i++) {
      doc.setPage(i)
      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.2, strokeOpacity: 1 }));
      doc.setTextColor('#1D1D1D40');
      // doc.text('DRAFT', 38, 15, { align: 'center', baseline: 'middle' })
      doc.restoreGraphicsState();
    }
    doc.html(wrapper, {
      callback: function (doc) {
        doc.save(fileName);
      },
      autoPaging: 'text',
      html2canvas: { scale: 0.6 },
      margin: [25, 0, 25, 0],
      x: 18,
      y: 0
    });
  };

  // *********** Render Functions ***********

  const RENDER_LEG_VALUE = {
    arrival_to_airport_iata: (item) => (
      <span className='flex items-center'>
        {`${item?.departure_airport_icao} / ${item?.departure_airport_iata} `}
        <img src={'/right-arrow.png'} className='px-1' />
        {` ${item?.arrival_airport_icao} / ${item?.arrival_airport_iata}`}
        {item?.description && item?.description?.length
          ? <span className='w3-tooltip cursor-pointer flex'>
            <span className="absolute left-0 tooltip-top w3-text w3-tag w-52 w3-small"> {item?.description} </span>
            <History className='small-left-margin w-6 h-6' />
          </span>
          : null
        }
      </span>
    ),
    unit_cost: (item) => (<span> {formatPrice(Number(item?.unit_cost || 0))} </span>),
    total_cost: (item) => (<span> {formatPrice(Number(item?.total_cost || 0))} </span>),
    vat_amount: (item) => (<span> {formatPrice(Number(item?.vat_amount || 0))} </span>)
  };

  const INVOICE_DETAILS_SECTION = () => (
    <React.Fragment>
      <span className='table-heading'>
        <div>Invoice ID: {`${form?.id}`}</div>
      </span>
      <table className="custom-table">
        <tbody>
          <tr>
            <td className="title">Created Date</td>
            <td className="text">{dayjs(ignoreTimeZone(form?.createdat)).format('YYYY-MM-DD')}</td>
            <td colSpan={1} className="title">Updated Date</td>
            <td colSpan={3} className="text">{dayjs(ignoreTimeZone(form?.updatedat)).format('YYYY-MM-DD')}</td>
            <td className='hidden'></td>
            <td className='hidden'></td>
          </tr>
          <tr>
            <td className="title">Invoice Type</td>
            <td className="text">Block Hour</td> 
            <td className="title">Request ID</td>
            <td className="text">
              <Link
                target='__blank'
                className='w3-text-blue flex items-center w-full text-center justify-center'
                to={`/requests/view/${form?.request_id}`}
              >
                {form?.request_id}
              </Link>
             </td>
            <td className="title">Aircraft Registration</td>
            <td className="text">
              {
                (form?.block_hour_invoice_item &&
                  form?.block_hour_invoice_item?.find(item => item?.aircraft_tail_number)?.aircraft_tail_number
                ) || ''
              }
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );

  const INVOICE_COST_ITEMS = () => (
    <React.Fragment>
      <span className='table-heading'>
        <div>Cost Items</div>
      </span>
      <TableLite
        headers={headers}
        customHeaders={CustomHeaders}
        customRenderCell={RENDER_LEG_VALUE}
        data={form?.block_hour_invoice_item || []}
      />
      <table className='react-table-lite-table w3-border-bottom'>
        <tbody>
          <tr>
            <td className='w3-large bold'>
              <div className='flex justify-between px-1 py-2 text-primary-blue'>
                <span> Total: </span>
                <span>{formatPrice(Number(form?.invoice_amount || 0))} </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='h-16'></div>
    </React.Fragment>
  );

  const DOWNLOAD_PDF_BUTTON = () => (
    <button
      onClick={handleDownloadPdf}
      className={`pdf-download-button fixed bottom-0 right-0 w3-btn bg-primary-blue w3-text-white shadow-lg w3-circle`}
    >
      <Save className='h-10 w-10' />
    </button>
  );

  return (
    <div id='Invoice-View'>
      <div className='request-block'>
        {INVOICE_DETAILS_SECTION()}
        {INVOICE_COST_ITEMS()}
        {DOWNLOAD_PDF_BUTTON()}
      </div>
    </div>
  )
}

export default BlockHourInvoicesView;