// Packages
import React, { useState, useEffect } from "react";
import { useNavigate, useRoutes, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";

// Apis
import { LogoutRequest, LoggedUserDetailsRequest, CompaniesListRequest, UserEditRequest, FleetByUserListRequest, UserFleetEditRequest } from "../../requests";

// Utils
import {
  SideNav,
  Header,
  Modal,
  User,
  Crew,
  HealthInsurance,
  Services,
  ContractServices,
  ServiceRequest,
  Plane,
  Fleet,
  Gear,
  Logout,
  X,
  Location,
  HotelRequests,
  Invoices,
  Ticket,
  FilterList,
  Bills
} from "../../components";
import { auth, loggedUserInfoCache, companiesCache } from "../../atoms";
import { constants, decodeString, decodeToken, authenticationErrorHandle } from "../../utils";
import RequestList from "./request-list";
import RequestForm from "./request-form";
import RequestView from "./request-view";
import AircraftList from "./aircraft-list";
import AircraftForm from "./aircraft-form";
import AirbasesList from "./airbases-list";
import AirbaseForm from "./airbase-form";
import AirportsList from "./airports-list";
import AirportsForm from "./airports-form";
import CrewMembersList from "./crewmembers-list";
import CrewMembersForm from "./crewmembers-form";
import ServicesList from "./services-list";
import ServicesForm from "./services-form";
import FleetList from "./fleet-list";
import FleetForm from "./fleet-form";
import UserList from "./user-list";
import UserForm from "./user-form";
import ContractServicesList from "./contractservices-list";
import ContractServicesForm from "./contractservices-form";
import HotelRequestList from "./hotelrequest-list";
import HotelRequestForm from "./hotelrequest-form";
import HotelRequestView from "./hotelrequest-view";
import ApprovedRequestView from "./approved-request-view";
import BlockHourInvoicesList from "./blockhour-invoices-list";
import BlockHourInvoicesForm from "./blockhour-invoices-form";
import BlockHourInvoicesView from "./blockhour-invoices-view";
import CostPlusInvoiceList from './costplus-invoice-list';
import CostPlusInvoicesForm from "./costplus-invoice-form";
import CostPlusInvoicesView from "./costplus-invoice-view";
import TicketRequestList from "./ticketrequest-list";
import TicketRequestView from "./ticketrequest-view";
import TicketRequestForm from "./ticketrequest-form";
import FilteredList from "./filtered-list";
import ApprovedHotelRequestView from "./approved-hotelrequest-view";
import ApprovedTicketRequestView from "./approved-ticketrequest-view";
import AircraftInvoicesList from "./aircraft-invoices-list";
import InsuranceRequestForm from "./insurancerequest-form";
import InsuranceRequestList from "./insurancerequest-list";
import InsuranceRequestView from "./insurancerequest-view";
import ApprovedInsuranceRequestView from "./approved-insurancerequest-view";
import AirbaseInvoicesList from "./airbase-invoices-list";
import FilteredInvoicesList from "./filteredinvoices-list";
import ReportsSelectionView from "./reports-selection-view";
import InvoicesPdfView from "./invoices-pdf-view";
import HotelsReportView from "./hotels-report-view";
import CateringReportView from "./catering-report-view";
import NewCostPlusInvoiceForm from "./new-costplus-invoice-form";
import BillingSelectionView from "./billing-selection-view";
import HotelBillingView from "./hotels-billing-view";
import FetchBillsList from "./fetch-bills-list";

function AuthenticatedRoutes(props) {
  const Navigate = useNavigate();
  const WindowLocation = useLocation();
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [companies, _companies] = useAtom(companiesCache);
  const [userCompany, _userCompany] = useState(null);
  const [nonadminUserFleets, _nonadminUserFleets] = useState([]);
  const [nonadminUserFleet, _nonadminUserFleet] = useState(null);
  const [showLogoutModal, _showLogoutModal] = useState(false);
  const [showCompanySelectionModal, _showCompanySelectionModal] = useState(false);
  const [showFleetSelectionModal, _showFleetSelectionModal] = useState(false);
  const [name, _name] = useState("");

  // fetch loggedIn user details and companies on login
  useEffect(() => {
    if (authState) {
      if (!loggedUserInfo || !loggedUserInfo.created || Date.now() - loggedUserInfo.created >= 1200000) {
        getLoggedUserDetails();
      }
      if (!companies || !companies.created || Date.now() - companies.created >= 1200000) {
        getCompanies();
      }
    }
  }, [authState])

  // set the selected company for the company selection modal on loggedin user info
  useEffect(() => {
    if (loggedUserInfo && loggedUserInfo?.data) {
      _userCompany(loggedUserInfo?.data?.company)
    }
  }, [loggedUserInfo?.data, companies?.data])

  // set the selected fleet for non admin users
  useEffect(() => {
    if (loggedUserInfo && loggedUserInfo?.data && !loggedUserInfo?.data?.is_staff) {
      getNonAdminFleetList();
      _nonadminUserFleet(loggedUserInfo?.data?.fleet?.id);
    }
  }, [loggedUserInfo?.data])

  const UNDERDEV = () => (
    <div
      className="w-full text-center py-3 w3-section"
      style={{ color: "rgb(23, 24, 71)" }}
    >
      <div>
        <svg
          className="w-16 h-16"
          fill="currentColor"
          style={{ display: "inline-block", verticalAlign: "middle" }}
          height={32}
          width={32}
          viewBox="0 0 512 512"
        >
          <path d="M461.9,114.9l-56.5,56.7l-55.1-10l-9.9-55.1l56.5-56.7c-12.7-12.7-30.8-18.5-44.2-17.8c-13.5,0.7-42.3,8.3-64.6,32c-21.6,22.8-44.3,65.3-24.2,112.5c2.4,5.7,5.1,13.2-2.9,21.2c-8.1,8-215,202.8-215,202.8c-19.4,16.7-18,47.6-0.1,65.6c18.2,17.9,48.9,19,65.6-0.3c0,0,193.2-205.8,202.7-215.1c8.5-8.3,16.1-5.5,21.2-2.9c35.6,18.4,86.3,2.4,112.6-23.9c26.3-26.3,31.1-51.7,31.9-64.7C480.7,146.3,476.2,129.2,461.9,114.9z M91.3,443.2c-6.3,6.2-16.5,6.2-22.7,0c-6.2-6.3-6.2-16.5,0-22.7c6.3-6.2,16.5-6.2,22.7,0C97.5,426.8,97.5,437,91.3,443.2z" />
        </svg>
      </div>
      <span className="w3-xxxlarge">Under Development</span>
    </div>
  );

  const NavLinks = [
    {
      path: "/requests",
      name: "requests",
      label: "Flight Requests",
      icon: <ServiceRequest className="w-6 h-6" />,
    },
    // {
    //   path: "/users",
    //   name: "users",
    //   label: "Users",
    //   icon: <UserOutline className="w-6 h-6" />,
    // },
    {
      path: "/hotel-reservations",
      name: "hotelrequests",
      label: "Hotel Reservation",
      icon: <HotelRequests className="w-6 h-6" />,
    },
    {
      path: "/insurance-requests",
      name: "insurancerequests",
      label: "Insurance Requests",
      icon: <HealthInsurance className="w-6 h-6" />,
    },
    {
      hasSubheader: "Database",
      path: "/crew-members",
      name: "crewmembers",
      label: "Crew Members",
      icon: <Crew className="w-6 h-6" />,
    },
    // {
    //   path: "/ticket-requests",
    //   name: "ticketrequests",
    //   label: "Ticket Requests",
    //   icon: <Ticket className="w-6 h-6" />,
    // },
    {
      path: "/aircrafts",
      name: "aircrafts",
      label: "Aircrafts",
      icon: <Plane className="w-6 h-5" />,
    },
    {
      path: "/fleets",
      name: "fleets",
      label: "Fleets",
      icon: <Fleet className="w-6 h-6" />,
    },
    {
      path: "/airbases",
      name: "airbases",
      label: "Airbases",
      icon: <Location className="w-6 h-6" />,
    },
    {
      path: "/airports",
      name: "airports",
      label: "Airports",
      icon: <Plane className="w-6 h-6" />,
    },
    // Only id 2 user "Bizadmin" will see service tab
    loggedUserInfo?.data?.id === 2 &&
    {
      path: "/services",
      name: "services",
      label: "Services",
      icon: <Services className="w-6 h-6" />,
    },
    {
      path: "/filtered-list",
      name: "filtered list",
      label: "Filtered List",
      icon: <FilterList className="w-6 h-6" />,
    },
    {
      hasSubheader: "Invoices",
      path: "/contract-service",
      name: "contract service",
      label: "Contract Service",
      icon: <ContractServices className="w-6 h-6" />,
    },
    {
      path: "/block-hour-invoices",
      name: "Block Hour Invoices",
      label: "Block Hrs Invoices",
      icon: <Invoices className="w-6 h-7" />,
    },
    {
      path: "/costplus-invoices",
      name: "Cost Plus Invoices",
      label: "Cost Plus Invoices",
      icon: <Invoices className="w-6 h-7" />,
    },
    // {
    //   path: "/billing",
    //   name: "Billing",
    //   label: "Billing",
    //   icon: <Bills className="w-6 h-7" />,
    // },
    //  {
    //   path: "/hotel-invoices",
    //   name: "Hotel Invoices",
    //   label: "Hotel Invoices",
    //   icon: <Invoices className="w-6 h-7" />,
    // },
    {
      path: "/reports",
      name: "Reporting",
      label: "Reporting",
      icon: <Invoices className="w-6 h-7" />,
    },
    // {
    //   path: "/fetch-bills",
    //   name: "Billing",
    //   label: "Billing",
    //   icon: <Bills className="w-6 h-7" />,
    // },
    {
      path: "/settings",
      name: "settings",
      label: "Settings",
      icon: <Gear className="w-6 h-6" />,
    },
  ]?.filter(Boolean);

  const Routes = [
    { path: "*", element: <RequestList />, heading: "Service Requests" },
    { path: "/requests", element: <RequestList />, heading: "Requests" },
    { path: "/requests/new", element: <RequestForm />, heading: "Request" },
    { path: "/requests/view/:requestId", element: <RequestView />, heading: "Request" },
    { path: "/requests/edit/:requestId", element: <RequestForm />, heading: "Request" },
    { path: "/requests/history/view/:requestId", element: <ApprovedRequestView />, heading: "Request" },
    { path: "/crew-members", element: <CrewMembersList />, heading: "Crew Members" },
    { path: "/crew-members/new", element: <CrewMembersForm />, heading: "Crew Member" },
    { path: "/crew-members/edit/:crewMemberId", element: <CrewMembersForm />, heading: "Crew Member" },
    { path: "/hotel-reservations", element: <HotelRequestList />, heading: "Hotel Reservations" },
    { path: "/hotel-reservations/new", element: <HotelRequestForm />, heading: "Hotel Reservation" },
    { path: "/hotel-reservations/edit/:hotelRequestId", element: <HotelRequestForm />, heading: "Hotel Reservation" },
    { path: "/hotel-reservations/view/:hotelRequestId", element: <HotelRequestView />, heading: "Hotel Reservation" },
    { path: "/approved-hotel-reservations/view/:hotelRequestId", element: <ApprovedHotelRequestView />, heading: "Hotel Reservation" },
    { path: "/insurance-requests", element: <InsuranceRequestList />, heading: "Insurance Requests" },
    { path: "/insurance-requests/view/:insuranceRequestId", element: <InsuranceRequestView />, heading: "Insurance Request" },
    { path: "/insurance-requests/new", element: <InsuranceRequestForm />, heading: "Insurance Request" },
    { path: "/insurance-requests/edit/:insuranceRequestId", element: <InsuranceRequestForm />, heading: "Insurance Request" },
    { path: "/approved-insurance-requests/view/:insuranceRequestId", element: <ApprovedInsuranceRequestView />, heading: "Hotel Reservation" },

    { path: "/services", element: <ServicesList />, heading: "Services" },
    { path: "/services/new", element: <ServicesForm />, heading: "Service" },
    { path: "/services/edit/:serviceId", element: <ServicesForm />, heading: "Service" },
    { path: "/ticket-requests", element: <TicketRequestList />, heading: "Ticket Requests" },
    { path: "/ticket-requests/new", element: <TicketRequestForm />, heading: "Ticket Request" },
    { path: "/ticket-requests/edit/:ticketRequestId", element: <TicketRequestForm />, heading: "Ticket Request" },
    { path: "/ticket-requests/view/:ticketRequestId", element: <TicketRequestView />, heading: "Ticket Request" },
    { path: "/approved-ticket-request/view/:ticketRequestId", element: <ApprovedTicketRequestView />, heading: "Ticket Request" },

    { path: "/aircrafts", element: <AircraftList />, heading: "Aircrafts" },
    { path: "/aircrafts/new", element: <AircraftForm />, heading: "Aircraft" },
    { path: "/aircrafts/edit/:aircraftId", element: <AircraftForm />, heading: "Aircraft" },
    { path: "/airbases", element: <AirbasesList />, heading: "Airbases" },
    { path: "/airbases/new", element: <AirbaseForm />, heading: "Airbase" },
    { path: "/airbases/edit/:airbaseId", element: <AirbaseForm />, heading: "Airbase" },
    { path: "/airports", element: <AirportsList />, heading: "Airport" },
    { path: "/airports/new", element: <AirportsForm />, heading: "Airport" },
    { path: "/airports/edit/:airportId", element: <AirportsForm />, heading: "Airport" },
    { path: "/settings", element: <UNDERDEV />, heading: "Settings" },
    { path: "/fleets", element: <FleetList />, heading: "Fleet" },
    { path: "/fleets/new", element: <FleetForm />, heading: "Fleet" },
    { path: "/fleets/edit/:fleetId", element: <FleetForm />, heading: "Fleet", },
    { path: "/users", element: <UserList />, heading: "Users" },
    { path: "/users/new", element: <UserForm />, heading: "User" },
    { path: "/users/edit/:userId", element: <UserForm />, heading: "User" },
    { path: "/contract-service", element: <ContractServicesForm />, heading: "Contract Service" },
    { path: "/block-hour-invoices", element: <BlockHourInvoicesList />, heading: "Block Hour Invoices" },
    { path: "/block-hour-invoices/edit/:invoiceId", element: <BlockHourInvoicesForm />, heading: "Block Hour Invoice", },
    { path: "/block-hour-invoices/view/:invoiceId", element: <BlockHourInvoicesView />, heading: "Block Hour Invoice", },
    { path: "/costplus-invoices", element: <CostPlusInvoiceList />, heading: "Cost Plus Invoices" },
    { path: "/costplus-invoices/view/:costplusinvoiceId", element: <CostPlusInvoicesView />, heading: "Cost Plus Invoices" },
    { path: "/costplus-invoices/edit/fsr/:costplusinvoiceId", element: <CostPlusInvoicesForm />, heading: "Cost Plus Invoice" },
    { path: "/costplus-invoices/new", element: <NewCostPlusInvoiceForm />, heading: "Cost Plus Invoice" },
    { path: "/costplus-invoices/edit/hotel/:costplusinvoiceId", element: <NewCostPlusInvoiceForm />, heading: "Cost Plus Invoice" },
    { path: "/filtered-list", element: <FilteredList />, heading: "Filtered List" },
    { path: "/reports", element: <ReportsSelectionView />, heading: "Reports" },
    { path: "/reports/aircraft-invoices", element: <AircraftInvoicesList />, heading: "Aircraft Invoice" },
    { path: "/reports/airbase-invoices", element: <AirbaseInvoicesList />, heading: "Aircraft Invoice" },
    { path: "/reports/filtered-invoices", element: <FilteredInvoicesList />, heading: "Filtered Invoices" },
    { path: "/reports/invoice-pdf-view", element: <InvoicesPdfView />, heading: "Invoices PDF view" },
    { path: "/reports/hotel-requests-report", element: <HotelsReportView />, heading: "Hotel Booking Report" },
    { path: "/reports/catering-requests-report", element: <CateringReportView />, heading: "Catering Report" },
    { path: "/billing", element: <BillingSelectionView />, heading: "Billing" },
    { path: "/billing/hotels", element: <HotelBillingView />, heading: "Hotel Billing" },
    { path: "/fetch-bills", element: <FetchBillsList />, heading: "Fetch Bills" },
    // { path: "/contract-services/reports/new", element: <ContractServicesForm />, heading: "Contract Service" },
    // { path: "/contract-services/edit/:contractServiceId", element: <ContractServicesForm />, heading: "Contract Service", },
  ];

  const LinkedPaths = () => {
    // return routes according to permission of is_staff json check
    return useRoutes(loggedUserInfo && loggedUserInfo?.data?.is_staff ? Routes : Routes.slice(0, 18));
  };

  const getLoggedUserDetails = () => {
    const token = decodeString(authState);
    LoggedUserDetailsRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: [constants.SESSIONTIMEOUT] }
        )
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        // Keep server data in cache with current time
        _loggedUserInfo({
          data: { ...data },
          created: Date.now()
        });
        if (WindowLocation.pathname == '/') Navigate('/requests');
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          // Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch logged user info' });
        }
      )
  };

  const getCompanies = () => {
    const token = decodeString(authState);
    CompaniesListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: [constants.SESSIONTIMEOUT] }
        )
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _companies({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          // Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch companies' });
        }
      )
  };

  const getNonAdminFleetList = () => {
    const token = decodeString(authState);
    FleetByUserListRequest(token, loggedUserInfo?.data?.id).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: [constants.SESSIONTIMEOUT] }
        )
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        // Keep server data in localstate        
        _nonadminUserFleets([...data.fleets]);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
        }
      )
  };

  // *********** Handlers ***********
  const handleUserClick = () => {
    if (authState) {
      const token = decodeString(authState);
      const data = decodeToken(token);
      _name(data ? data.name : "");
    }
    _showLogoutModal(true);
  };

  const handleCompanyBtnClick = () => {
    _showCompanySelectionModal(true);
  };

  const handleFleetBtnClick = () => {
    _showFleetSelectionModal(true);
  };

  const handleCloseLogoutModal = () => {
    _showLogoutModal(false);
  };

  const handleCloseCompanySelectionModal = () => {
    _showCompanySelectionModal(false);
  };

  const handleCloseFleetSelectionModal = () => {
    _showFleetSelectionModal(false);
  };

  const handleLogoutClick = () => {
    if (authState) {
      const token = decodeString(authState);
      LogoutRequest(token)
        .then((res) => res)
        .catch((err) => console.error(err))
        .finally(() => {
          localStorage.clear();
          Navigate(0);
        });
    }
  };

  const handleSelectCompany = (companyId) => {
    _userCompany(companyId);
  };

  const handleSelectFleet = (fleetId) => {
    _nonadminUserFleet(fleetId);
  };

  const handleChangeCompany = () => {
    if (loggedUserInfo && loggedUserInfo?.data && loggedUserInfo?.data?.id) {
      const token = decodeString(authState);
      const userId = loggedUserInfo?.data?.id;
      const data = JSON.parse(JSON.stringify(loggedUserInfo?.data));
      data.company = userCompany; // change company to update in database
      data.fleet = null;
      UserEditRequest(token, JSON.stringify(data), userId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: [constants.SESSIONTIMEOUT] }
            )
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            // getLoggedUserDetails();
            // Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Company changed' });
            handleCloseCompanySelectionModal();
            window.location.pathname = '/requests';
          }
        })
        .catch(err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  const handleChangeFleet = () => {
    if (nonadminUserFleet && loggedUserInfo?.data && loggedUserInfo?.data?.id) {
      const token = decodeString(authState);
      UserFleetEditRequest(token, nonadminUserFleet)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: [constants.SESSIONTIMEOUT] }
            )
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            handleCloseFleetSelectionModal();
            window.location.pathname = '/requests';
          }
        })
        .catch(err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  }

  const routeToSettings = () => {
    Navigate("/settings");
    handleCloseLogoutModal();
  };

  // *********** Render Functions ***********
  const ROUTE_VIEWS = () => <div id="Routes" className="content px-2">{LinkedPaths()}</div>;

  const LOGOUT_MODAL = () => (
    <Modal isOpen={showLogoutModal} modalClass="w-96 p-1 w3-round-medium">
      <div className="w-full text-center">
        <div className="w-full text-right">
          <X className="w-6 h-6 cursor-pointer" onClick={handleCloseLogoutModal} />
        </div>
        <User
          imageClass="w-14 h-14 w3-white"
          containerClass="w3-circle cursor-pointer"
        />
        <div className="py-1"> {loggedUserInfo && loggedUserInfo?.data?.username?.toUpperCase()} </div>
        <div className="w3-section"></div>
        <div className="w3-row">
          <div className="w3-col m6 l6 s6 cursor-pointer py-1 hover-gold border-right-light">
            <div
              onClick={routeToSettings}
              className="flex items-center w-full justify-center"
            >
              <Gear className="w-6 h-6" />{" "}
              <span className="px-1">Settings</span>
            </div>
          </div>
          <div className="w3-col m6 l6 s6 cursor-pointer py-1 hover-gold border-right-light">
            <div
              onClick={handleLogoutClick}
              className="flex items-center w-full justify-center"
            >
              <Logout className="w-5 h-6" />{" "}
              <span className="px-1">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  const COMPANY_SELECTION_MODAL = () => (
    <Modal isOpen={showCompanySelectionModal} modalClass="w-96 p-1 w3-round-medium">
      <div className="w-full p-2">
        <h3> Change Company </h3>
        {companies &&
          companies?.data &&
          companies?.data?.map(C => (
            <div key={C.id} className="py-1">
              <input
                type='radio'
                value={C.id}
                name='company'
                id={`company_${C.id}`}
                checked={userCompany === C.id}
                onChange={e => handleSelectCompany(C.id)}
              />
              <label htmlFor={`company_${C.id}`} className="px-1">{C.name}</label>
            </div>
          ))
        }
        <div className="h-3"></div>
        <div className="flex justify-end">
          <button onClick={handleCloseCompanySelectionModal} className='w3-btn w3-grey w3-text-white'> Cancel </button>
          <button onClick={handleChangeCompany} className='w3-btn bg-primary-blue w3-text-white small-left-margin'> Change </button>
        </div>
      </div>
    </Modal>
  );

  const FLEET_SELECTION_MODAL = () => (
    <Modal isOpen={showFleetSelectionModal} modalClass="w-96 p-1 w3-round-medium">
      <div className="w-full p-2">
        <h3> Change Fleet </h3>
        {nonadminUserFleets &&
          nonadminUserFleets?.map(Fl => (
            <div key={Fl.id} className="py-1">
              <input
                type='radio'
                value={Fl.id}
                name='fleet'
                id={`fleet_${Fl.id}`}
                checked={nonadminUserFleet === Fl.id}
                onChange={e => handleSelectFleet(Fl.id)}
              />
              <label htmlFor={`fleet_${Fl.id}`} className="px-1">{Fl.name}</label>
            </div>
          ))
        }
        <div className="h-3"></div>
        <div className="flex justify-end">
          <button onClick={handleCloseFleetSelectionModal} className='w3-btn w3-grey w3-text-white'> Cancel </button>
          <button onClick={handleChangeFleet} className='w3-btn bg-primary-blue w3-text-white small-left-margin'> Change </button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      <SideNav links={loggedUserInfo && loggedUserInfo?.data?.is_staff ? NavLinks : NavLinks.slice(0, 4)}>
        <Header
          onUserClick={handleUserClick}
          onCompanyBtnClick={handleCompanyBtnClick}
          onFleetBtnClick={handleFleetBtnClick}
          isStaff={loggedUserInfo && loggedUserInfo?.data?.is_staff}
          fleet={loggedUserInfo && loggedUserInfo?.data && loggedUserInfo?.data?.fleet}
          company={
            companies && loggedUserInfo && companies?.data && loggedUserInfo?.data
              ? companies?.data?.find(C => C.id === loggedUserInfo?.data?.company)?.name
              : ''
          }
        />
        {ROUTE_VIEWS()}
        {COMPANY_SELECTION_MODAL()}
        {FLEET_SELECTION_MODAL()}
        {LOGOUT_MODAL()}
      </SideNav>
    </>
  );
}

export default AuthenticatedRoutes;
