// Packages
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { ContractsListRequest, HotelReportDataRequest } from '../../../../requests';

// Utils
import { auth } from '../../../../atoms';
import { Loader, TableLite } from '../../../../components';

export default function BillsTable({
  billsList
}) {
  
  const [authState, _authState] = useAtom(auth);  
  const [isLoading, _isLoading] = useState(false);

  const BILLS_STATIC_DATA = [
    {
      fsr_id: "470",
      bill_id: "123",
      service_type: "Hotel",
      amount_total: "59500"
    },
    {
      fsr_id: "470",
      bill_id: "124",
      service_type: "Hotel",
      amount_total: "51621"
    },
    {
      fsr_id: "410",
      bill_id: "134",
      service_type: "Hotel",
      amount_total: "40000"
    },
  ];

  const BILLING_CUSTOM_HEADERS = {
    bill_id: "Bill Id",
    fsr_id: "FSR Id",
    service_type: "Service Type",
    amount_total: "Total Amount",  
  };



  // *********** Render Functions ***********

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const NO_BILLS = () => (
    <div className='small-top-margin'> No items Found </div>
  );

  const CUSTOM_RENDER_BILL_CELL = {    
    amount_total: (row) => <input type="number" min={0} defaultValue={row?.amount_total} className='w-24 no-focus' />
  };

  const BILLS_LIST = () => (
    <div className='w3-margin-top'>
    <TableLite
        showActions={false}
        downloadable={false}
        cellStyle={{ fontSize: "0.8em" }}
        data={billsList || BILLS_STATIC_DATA || []}        
        headers={Object.keys(BILLING_CUSTOM_HEADERS)}
        customHeaders={BILLING_CUSTOM_HEADERS}
        customRenderCell={CUSTOM_RENDER_BILL_CELL}        
      />
    </div>
  );

  return (
    <div id="bills-list">      
      {isLoading ? LOADER() : BILLS_LIST()}
    </div>
  );

};