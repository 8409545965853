// Packages
import React, { useState, useEffect } from 'react';
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";
import dayjs from 'dayjs';

// APIs
import { AirbaseListRequest, AircraftByFleetListRequest, ContractsListRequest, FleetByAirbaseListRequest } from '../../../requests';

// Utils
import { airbasesCache, auth, contractsCache } from '../../../atoms';
import { authenticationErrorHandle, constants, decodeString } from '../../../utils';
import { BillsTable } from './components';

export default function FetchBillsList(props) {

  const INITIAL_form = {
    contract: "",
    fleet: "",
    aircraft: "",
    airbase: "",
    month: "",
    year: dayjs().format('YYYY'),
    service_type: "Hotel with FSR"
  };

  const [form, _form] = useState({ ...INITIAL_form });
  const [authState, _authState] = useAtom(auth);
  const [contracts, _contracts] = useAtom(contractsCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [fleet, _fleet] = useState([]);
  const [aircrafts, _aircrafts] = useState([]);
  const [billsList, _billsList] = useState([]);


  useEffect(() => {
    if (form?.airbase) {
      getFleetByAirbase();
    }
  }, [form?.airbase]);

  useEffect(() => {
    if (form?.fleet) {
      getAircraftsByFleet();
    }
  }, [form?.fleet]);

  useEffect(() => {
    if (authState) {
      if (!contracts || !contracts.created || Date.now() - contracts.created >= 1200000) {
        getContracts()
      }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState]);

  const getContracts = () => {
    const token = decodeString(authState);
    ContractsListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _contracts({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({
          ...constants.ERRORTOAST,
          message: "Failed to fetch contracts",
        });
      });
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData) {
          // Keep server data in cache with current time
          _airbases({
            data: [...nonPaginatedData],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({
          ...constants.ERRORTOAST,
          message: "Failed to fetch airbases",
        });
      });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, form?.airbase).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _fleet({
          data: [...nonPaginatedData],
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch fleet" });
    });
  };

  const getAircraftsByFleet = () => {
    const token = decodeString(authState);
    AircraftByFleetListRequest(token, form?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  const getBillsList = () => {

  };

  // *********** Handlers ***********
  const handleChangeFilters = (e) => {
    _form(old => ({
      ...old,
      [e.target.name]: e.target.value
    }));
  };

  // *********** Render Functions ***********

  const FILTERS = () => (
    <div>
      <div className='w3-row-padding w3-stretch'>
        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Contract </label>
          <select
            id="contract"
            name="contract"
            value={form?.contract}
            onChange={handleChangeFilters}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Contract </option>
            {contracts &&
              contracts?.data &&
              contracts?.data
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Airbase </label>
          <select
            id="airbase"
            name="airbase"
            value={form?.airbase}
            onChange={handleChangeFilters}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Airbase </option>
            {airbases &&
              airbases?.data &&
              airbases?.data
                ?.map((Ab, index) => (
                  <option value={Ab.id} key={index}> {Ab.name} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Fleets </label>
          <select
            id="fleet"
            name="fleet"
            value={form?.fleet}
            onChange={handleChangeFilters}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Fleet </option>
            {fleet &&
              fleet?.data &&
              fleet?.data
                ?.map((Fl, index) => (
                  <option value={Fl.id} key={index}> {Fl.name} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Aircraft </label>
          <select
            id="aircraft"
            name="aircraft"
            value={form?.aircraft}
            onChange={handleChangeFilters}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Aircraft </option>
            {aircrafts &&
              aircrafts?.data &&
              aircrafts?.data
                ?.map((A, index) => (
                  <option value={A.id} key={index}> {A.tailnumber} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Service Type </label>
          <select
            id="service_type"
            name="service_type"
            value={form?.service_type}
            onChange={handleChangeFilters}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >            
            <option value={"Hotel with FSR"}> Hotel with FSR </option>
            <option value={"Standalone Hotels"}> Standalone Hotels </option>
            <option value={"Catering"}> Catering </option>
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Month </label>
          <select
            id='month'
            name="month"
            value={form?.month}
            onChange={handleChangeFilters}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Month </option>
            {constants?.MONTHS?.map((option, index) => <option key={option} value={index + 1}> {option} </option>)}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Year </label>
          <select
            id='year'
            name='year'
            value={form?.year}
            onChange={handleChangeFilters}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Year </option>
            {Array.from({ length: parseInt(dayjs().year()) - 2024 + 1 }, (_, i) => i + 2024).map((item) => (
              <option value={item} key={item}> {item} </option>
            ))
            }
          </select>
        </div>

        <div className="w3-col s12 m8">
          <label className='w3-medium no-opacity'> Year </label>
          <div className="flex justify-end wrap small-top-margin">
            <button className='w3-btn bg-primary-blue w3-text-white small-left-margin'> Fetch Bills </button>
            <button className='w3-btn bg-primary-blue w3-text-white small-left-margin'> Save Bills </button>
          </div>
        </div>
      </div>
    </div>
  );

  const BILLS_LIST = () => (
    <BillsTable />
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className="w-full p-2">
        {FILTERS()}
        {BILLS_LIST()}
      </div>
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  );
}
