// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { useAtom } from 'jotai';

// APIs 
import { ServiceListRequest, CostPlusInvoiceDetailsRequest, CostPlusInvoiceEditRequest, ApprovedFsrDetailsRequest, ContractServiceDetailsRequest, FetchBillsWebhookRequest } from '../../../requests';

// Utils
import { Bills, Loader, Plus, X } from '../../../components';
import { CostListItemModal } from './components';
import { auth, servicesCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone } from '../../../utils';

function CostPlusInvoicesForm() {

  const Navigate = useNavigate();
  const { costplusinvoiceId } = useParams();

  const INITIAL_FORM = {
    approve_request_id: '',
    contract_id: '',
    status: 'Draft',
    request_id: '',
    aircraft: null,
    items_to_delete: [],
    items_to_create: [],
    items_to_update: [],
    cost_plus_invoice_item: []
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(costplusinvoiceId ? true : false);
  const [showCostListItemModal, _showCostListItemModal] = useState(false);
  const [parentRequest, _parentRequest] = useState(null);
  const [selectedCostListItem, _selectedCostListItem] = useState(null);
  const [contractServices, _contractServices] = useState([]);
  const [billsFetched, _billsFetched] = useState(false);
  const [isFetchting, _isFetchting] = useState(false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [services, _services] = useAtom(servicesCache);

  // Fetch invoice details
  // Also fetch services either from cache or from server
  useEffect(() => {
    if (costplusinvoiceId && authState) {
      getCostPlusInvoiceDetails();
    }
    if (!services || !services.created || Date.now() - services.created >= 1200000) {
      getServices();
    }
  }, [authState, costplusinvoiceId]);

  // Get the contract services from fleet of the parent request
  useEffect(() => {
    if (parentRequest?.fleet && authState) {
      getContractServiceDetails();
    }
  }, [authState, parentRequest?.fleet]);

  // Fetch parent request of invoice
  useEffect(() => {
    if (Boolean(form?.approve_request_id)) {
      getApprovedFsrDetails();
    }
  }, [authState, form?.approve_request_id]);

  const getCostPlusInvoiceDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    CostPlusInvoiceDetailsRequest(token, costplusinvoiceId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        const cost_plus_invoice_item = [...data?.cost_plus_invoice_item]?.map((item, index) => (
          {
            ...item,
            temp_id: `temp_${index}`,
            unit_cost: Number(item?.unit_cost).toFixed(3),
            total_cost: Number(item?.total_cost).toFixed(3),
            vat_percentage: Number(item?.vat_percentage).toFixed(3),
          }
        ))?.sort((a, b) => {
          if (a.approve_request_leg === b.approve_request_leg) {
            return b.service_location.localeCompare(a.service_location);
          }
          return a.approve_request_leg > b.approve_request_leg;
        });
        const newData = {
          ...data,
          items_to_delete: [],
          items_to_create: [],
          items_to_update: [],
          cost_plus_invoice_item
        };
        _isLoading(false);
        _form(newData);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch cost invoice details' });
        }
      )
  };

  const getApprovedFsrDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    ApprovedFsrDetailsRequest(token, form?.approve_request_id).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _parentRequest({ ...data });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      _isLoading(false);
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch request details" });
    });
  };

  const getServices = () => {
    const token = decodeString(authState);
    ServiceListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _services({
          data: [...data.results],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch services" });
    });
  };

  const getContractServiceDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    ContractServiceDetailsRequest(token, parentRequest?.fleet).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        data = data?.map(d => (
          {
            ...d,
            price: Number(d?.price).toFixed(2)
          })
        )
          || [];
        _contractServices(data);
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(err => {
      _isLoading(false);
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch contract service details' });
    }
    )
  };

  // *********** Handlers ***********

  const handleFieldChange = (e, tempId, fieldName) => {
    // Clone the form data to avoid mutating it directly
    var updatedForm = { ...form };
    updatedForm.cost_plus_invoice_item = updatedForm.cost_plus_invoice_item?.map(C => {
      if (C?.temp_id === tempId) {
        C[fieldName] = e.target.value;
      } return C;
    });
    const updatedItem = updatedForm.cost_plus_invoice_item?.find(C => C?.temp_id === tempId);
    // If an existing cost plus item is being editted, store it in items to update array
    updatedForm.items_to_update = updatedForm?.items_to_update?.filter(I => I?.id !== updatedItem?.id)
    updatedForm.items_to_update.push(updatedItem);
    // Update the form state with the new data
    _form(updatedForm);
  };

  const handleRemoveCostListItem = (item) => {
    _form(old => ({
      ...old,
      cost_plus_invoice_item: [...old?.cost_plus_invoice_item]?.filter(I => I?.temp_id != item?.temp_id),
      items_to_create: [...old?.items_to_create]?.filter(I => I?.temp_id != item?.temp_id),
      items_to_update: [...old?.items_to_update]?.filter(I => I?.temp_id != item?.temp_id),
      items_to_delete: item?.id ? [...old?.items_to_delete, item?.id] : [...old?.items_to_delete]
    }));
  };

  const handleAddNewCostListItems = () => {
    const NewCostItems = {};
    _selectedCostListItem(NewCostItems);
    handleOpenCostListItemModal();
  };

  const handleOpenCostListItemModal = () => {
    _showCostListItemModal(true);
  };

  const handleCloseCostListItemModal = () => {
    _showCostListItemModal(false);
    _selectedCostListItem(null)
  };

  const handleSaveCostListItem = (newCostPlusListItems) => {
    const items_to_create = [...form?.items_to_create];
    const cost_plus_invoice_item = [...form?.cost_plus_invoice_item];
    const cost_item_fields = { ...newCostPlusListItems };
    delete cost_item_fields.services;
    newCostPlusListItems.services.forEach((S, index) => {
      const costItem = { ...S, ...cost_item_fields };
      const selectedLegId = newCostPlusListItems?.approve_request_leg;
      const selectedServiceId = costItem?.service_id;
      const legInfo = parentRequest?.approve_request_leg?.find(L => L?.id == selectedLegId);
      const serviceInfo = services?.data?.find(S => S?.id == selectedServiceId);
      costItem.is_local = legInfo?.is_local;
      costItem.service_name = serviceInfo?.name;
      costItem.cost_plus_invoice = costplusinvoiceId;
      costItem.arrival_to_airport_iata = legInfo?.destination_airport_iata;
      costItem.arrival_to_airport_icao = legInfo?.destination_airport_icao;
      costItem.arriving_from_airport_iata = legInfo?.departure_airport_iata;
      costItem.arriving_from_airport_icao = legInfo?.departure_airport_icao;
      costItem.total_cost = parseFloat(costItem?.unit_cost)?.toFixed(3) * parseInt(costItem?.quantity);
      cost_plus_invoice_item.push(costItem);
      items_to_create.push(costItem);
    });
    _form(old => ({
      ...old,
      items_to_create,
      cost_plus_invoice_item
    }));
    _selectedCostListItem(null);
    handleCloseCostListItemModal();
  };

  const handleClearAllCostItems = () => {
    _form(old => ({
      ...old,
      items_to_delete: old?.cost_plus_invoice_item?.filter(I => I?.id)?.map(I => I.id),
      items_to_create: [],
      items_to_update: [],
      cost_plus_invoice_item: []
    }));
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = { ...form }
    if (data?.cost_plus_invoice_item?.length < 1) {
      Store.addNotification({ ...constants.ERRORTOAST, message: 'No invoice items present' });
      return;
    }
    _isSubmitting(true);
    data.invoice_amount = data?.cost_plus_invoice_item.reduce((acc, C) => acc += Number(C.total_cost), 0);
    data.invoice_amount = data.invoice_amount?.toFixed(4);
    CostPlusInvoiceEditRequest(token, JSON.stringify(data), costplusinvoiceId)
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Invoice updated' });
          Navigate("/costplus-invoices");
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const handleFetchBills = () => {
    const token = decodeString(authState);
    const hardcodded = '1234';
    const FSRID = form?.request_id;
    if(isFetchting) return;
    _isFetchting(true);
    FetchBillsWebhookRequest(token, hardcodded)
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _billsFetched(true);
          _isFetchting(false);
          handleCreateBillItems(data?.bill_record);
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Invoice items fetched' });
        }
      })
      .catch(err => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const handleCreateBillItems = (billItems) => {
    const items_to_create = [...form?.items_to_create];
    const cost_plus_invoice_item = [...form?.cost_plus_invoice_item];
    const existingCostListItems = Array.isArray(form?.cost_plus_invoice_item) &&
      [...new Set(form?.cost_plus_invoice_item)]?.sort((a, b) => Number(a?.approve_request_leg) - Number(b?.approve_request_leg));
    billItems.forEach((billItem, index) => {
      const newlyCreatedBillItem = { ...existingCostListItems?.[0] };
      delete newlyCreatedBillItem.id;
      newlyCreatedBillItem.unit_cost = 0;
      newlyCreatedBillItem.vat_percentage = 0;
      newlyCreatedBillItem.temp_id = `temp_${new Date().getTime()}${index}`;
      newlyCreatedBillItem.total_cost = parseFloat(billItem?.amount_total);
      if (billItem?.service_type?.toLowerCase().includes('catering')) {
        newlyCreatedBillItem.service_id = services?.data?.find(S => S?.name?.toLowerCase().indexOf('catering') > -1)?.id;
        newlyCreatedBillItem.service_name = services?.data?.find(S => S?.name?.toLowerCase().indexOf('catering') > -1)?.name;
      }
      else if (billItem?.service_type?.toLowerCase().includes('otel')) {
        newlyCreatedBillItem.service_id = services?.data?.find(S => S?.name?.toLowerCase().indexOf('otel') > -1)?.id;
        newlyCreatedBillItem.service_name = services?.data?.find(S => S?.name?.toLowerCase().indexOf('otel') > -1)?.name;
      }
      items_to_create.unshift(newlyCreatedBillItem);
      cost_plus_invoice_item.unshift(newlyCreatedBillItem);
    });

    _form(old => ({
      ...old,
      items_to_create,
      cost_plus_invoice_item
    }));
  };

  // *********** Render Functions ***********

  const COST_PLUS_INVOICE_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label className='w3-medium'> Invoice Id </label>
          <input
            type='text'
            disabled={true}
            value={form?.id || ''}
            // value={form?.name}
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label className='w3-medium'> Request Id </label>
          <input
            type='text'
            disabled={true}
            value={form?.request_id}
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label className='w3-medium'> Aircraft </label>
          <input
            type='text'
            disabled={true}
            value={form?.aircraft?.tail_number || ''}
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label className='w3-medium w3-text-white'> Button </label>
          <div className='w-full flex justify-between flex-wrap wrap'>
            <button onClick={handleClearAllCostItems} className='flex items-center small-top-margin small-bottom-margin w3-button w3-red w3-text-white'>
              <span> <X className='w-5 h-5 small-right-margin' /> </span>
              <span>Clear Invoice Items </span>
            </button>
            {/* {!billsFetched
              &&
              <span
                onClick={handleFetchBills}
                className='cursor-pointer flex items-center small-top-margin small-bottom-margin w3-text-blue w3-hover-text-grey'
              >
                <Bills className='w-6 h-6 small-right-margin' /> {isFetchting ? 'Fetching Bills' : 'Fetch Bills'}
              </span>
            } */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const LEG_WISE_COST_ITEMS = (approvedLegId) => {
    const approvedLegDetails = [...form?.cost_plus_invoice_item]?.find(item => item?.approve_request_leg == approvedLegId)
    const cost_items = [...form?.cost_plus_invoice_item]?.filter(item => item?.approve_request_leg == approvedLegId)
    return (
      <div key={approvedLegId} className='w3-row-padding'>
        <table className='cost-plus-invoice-view-table'>
          <thead>
            <tr>
              <th colSpan={7}>
                <div className='invoice-heading py-2'>
                  {`${approvedLegDetails?.arriving_from_airport_icao} / ${approvedLegDetails?.arriving_from_airport_iata} `}
                  →
                  {` ${approvedLegDetails?.arrival_to_airport_icao} / ${approvedLegDetails?.arrival_to_airport_iata}`}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className='bold'>
              <td>Service</td>
              <td>At</td>
              <td>Unit Cost</td>
              <td>Quantity</td>
              <td>Total Cost</td>
              <td>VAT %</td>
            </tr>
            {
              cost_items?.map((item, index) => (
                <tr key={index}>
                  <td className={`cost-plus-invoice-item-cell-300 ${!item?.id ? 'temp-cost-plus-invoice-item' : ''}`}>
                    <div className='flex wrap'>
                      {item?.service_name}
                    </div>
                  </td>
                  <td className='cost-plus-invoice-item-cell-80'>
                    <div className='flex wrap'>{item?.service_location}</div>
                  </td>
                  <td className='cost-plus-invoice-item-cell-80'>
                    <input
                      disabled={false}
                      type='number'
                      value={item?.unit_cost}
                      className='w-full w3-border w3-round p-1'
                      onChange={e => handleFieldChange(e, item?.temp_id, "unit_cost")}
                      onBlur={e => handleFieldChange({
                        target: { value: Number(item?.unit_cost).toFixed(3) }
                      }, item?.temp_id, "unit_cost")
                      }
                    />
                  </td>
                  <td className='cost-plus-invoice-item-cell-80'>
                    <input
                      disabled={false}
                      type='number'
                      value={item?.quantity}
                      className='w-full w3-border w3-round p-1'
                      onChange={e => handleFieldChange(e, item?.temp_id, "quantity")}
                      onBlur={e => handleFieldChange({
                        target: { value: Number(item?.quantity).toFixed(3) }
                      }, item?.temp_id, "quantity")
                      }
                    />
                  </td>
                  <td className='cost-plus-invoice-item-cell-80'>
                    <input
                      disabled={false}
                      type='number'
                      value={item?.total_cost}
                      className='w-full w3-border w3-round p-1'
                      onChange={e => handleFieldChange(e, item?.temp_id, "total_cost")}
                      onBlur={e => handleFieldChange({
                        target: { value: Number(item?.total_cost).toFixed(3) }
                      }, item?.temp_id, "total_cost")
                      }
                    />
                  </td>
                  <td className='cost-plus-invoice-item-cell-80'>
                    <input
                      disabled={false}
                      type='number'
                      value={item?.vat_percentage}
                      className='w-full w3-border w3-round p-1'
                      onChange={e => handleFieldChange(e, item?.temp_id, "vat_percentage")}
                      onBlur={e => handleFieldChange({
                        target: { value: Number(item?.vat_percentage).toFixed(3) }
                      }, item?.temp_id, "vat_percentage")
                      }
                    />
                  </td>
                  <td className='cost-plus-invoice-item-cell-80'>
                    <div className='cost-remove-btn-holder'>
                      {/* <label className='no-opacity w3-medium'> Delete </label> */}
                      <div
                        onClick={e => handleRemoveCostListItem(item)}
                        className='w3-button w3-pale-red small-left-margin'
                      >
                        <X className='w-5 h-5 w3-text-red' />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  };

  const COST_LIST_ITEMS = () => (
    Array.isArray(form?.cost_plus_invoice_item) &&
    [...new Set(form?.cost_plus_invoice_item?.map((item) => item?.approve_request_leg))]?.sort((a, b) => Number(a) - Number(b))?.map(leg => LEG_WISE_COST_ITEMS(leg))
  );


  const COST_LIST_ITEM_MODAL = () => (
    <CostListItemModal
      legs={parentRequest?.approve_request_leg || []}
      services={services}
      aircraftId={form?.aircraft?.id}
      aircraftTailNumber={form?.aircraft?.tail_number}
      allowedRoles={form?.allowed_roles || []}
      contractServices={contractServices}
      showCostListItemModal={showCostListItemModal}
      handleSaveCostListItem={handleSaveCostListItem}
      handleCloseCostListItemModal={handleCloseCostListItemModal}
    />
  );

  const ADD_COST_LIST_ITEM_BUTTON = () => (
    <div className='w3-row-padding small-top-margin'>
      <div className='w3-col s12 m12 l12'>
        <button
          className="flex items-center w3-btn bg-primary-blue w3-text-white"
          onClick={handleAddNewCostListItems}
        >
          <Plus className="w-5 h-5" />{" "}
          <span className="px-1"> Add Cost List Item </span>
        </button>
      </div>
    </div>
  );

  const COST_PLUS_TOTAL_SECTION = (item = {}) => (
    <div className='request-form-container w-half relative py-2' style={{ float: 'right', width: '30%' }}>
      <span className='heading'> Cost Plus Invoice Total</span>
      <div className='border-primary-blue w3-round p-2'>
        <div className='flex py-2'>
          <div className='w-full'>
            <label> Total Cost </label>
            <input
              maxLength={4}
              onChange={e => 0}
              className='w3-input w3-border w3-round'
              value={
                (() => {
                  let sum = 0;
                  form?.cost_plus_invoice_item.forEach((item) => {
                    sum += Number(item.total_cost) || 0;
                  });
                  return sum.toFixed(3);
                })()
              }
            />
          </div>
        </div>
      </div>
    </div>
  )

  const CONTENT = () => (
    <React.Fragment>
      <div className='request-form-container w-full relative'>
        <span className='heading'> Cost Plus Invoice </span>
        <div className='border-primary-blue w3-round p-2'>
          {COST_PLUS_INVOICE_DETAILS()}
          {COST_LIST_ITEMS()}
          {ADD_COST_LIST_ITEM_BUTTON()}
          {showCostListItemModal && COST_LIST_ITEM_MODAL()}
        </div>
      </div>
      {COST_PLUS_TOTAL_SECTION()}
      {FORM_FOOTER()}
    </React.Fragment>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  return (
    <div id='Invoice-Form'>
      {isLoading
        ? LOADER()
        : CONTENT()
      }
    </div>
  )

}

export default CostPlusInvoicesForm;
