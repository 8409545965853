// Packages
import React, { useState } from 'react';
import Select from 'react-select';

// Utils
import { Modal } from '../../../../components';

function CostListItemModal({
  form,
  legs,
  services,
  allowedRoles,
  aircraftId,
  aircraftTailNumber,
  contractServices,
  showCostListItemModal,
  handleSaveCostListItem,
  handleCloseCostListItemModal
}) {

  const INITIAL_COST_PLUS_ITEMS = {
    temp_id: `temp_${Math.ceil(Math.random() * new Date().getMilliseconds() + new Date().getSeconds())}`,
    aircraft_id: form?.aircraft_id,
    aircraft_tail_number: form?.aircraft_tail_number,
    service_location: null,
    status: 'New',
    services: []
  };

  const [newCostPlusListItems, _newCostPlusListItems] = useState(INITIAL_COST_PLUS_ITEMS);

  // The services to be selected in the new cost plus item can only be of type which is allowed in the user role
  let FilteredRolesServices = { ...services } || { data: [] };
  // FilteredRolesServices.data = FilteredRolesServices?.data?.filter(S => allowedRoles?.map(role => role?.toUpperCase())?.includes(S?.serviceCategory?.toUpperCase()));
  // if (allowedRoles?.find(role => ["HOTEL", "Hotel", "hotel", "hotac"].includes(role))) {
  //   FilteredRolesServices.data = [
  //     ...FilteredRolesServices.data,
  //     ...services?.data?.filter(S => S?.name?.includes('otel'))
  //   ];
  // }

  // *********** Handlers ***********

  // If any field related to the cost item changes
  const handleChangeCostItemField = (name, value) => {
    _newCostPlusListItems(old => ({
      ...old,
      [name]: value
    }))
  };

  // If any field related to the cost item service changes
  const handleChangeCostItemServiceField = (temp_Id, name, value) => {
    _newCostPlusListItems(old => ({
      ...old,
      services: old?.services?.map(S => {
        if (S?.temp_id === temp_Id) {
          S[name] = value
        } return S
      })
    }))
  };

  const handleSetCostItemsServices = (selectedServices) => {
    const oldServices = [
      ...newCostPlusListItems?.services
    ];
    const newServices = selectedServices?.map(S => {
      if (oldServices?.find(oldService => oldService?.service_id === S?.value))
        return oldServices?.find(oldService => oldService?.service_id === S?.value)
      else {
        let contractServicePrice = contractServices?.find(item => Number(item?.service) == S?.value)?.price || 0;
        const unit_cost = Number(contractServicePrice)?.toFixed(2);
        const newService = {
          temp_id: `temp_${new Date().getTime()}`,
          service_id: S?.value,
          service_name: S?.label,
          unit_cost,
          pax_count: 0,
          total_cost: 0,
          vat_amount: 0,
          crew_count: 0,
          quantity: 1,
          vat_percentage: 0,
        }
        return newService
      }
    })
    _newCostPlusListItems(old => ({
      ...old,
      services: newServices
    })
    );
  };

  return (
    <Modal isOpen={showCostListItemModal} modalClass="w3-round-medium">
      <div className="w-full">
        <div className='py-1 px-2 flex flex-wrap items-center'>
          <h3>
            Cost List Item
          </h3>
        </div>
        <div className='py-1'>
          <div className='w3-row-padding'>
            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Select Leg </label>
                <Select
                  className='w-full small-top-margin small-bottom-margin'
                  options={legs && legs?.map(L => ({
                    value: L?.id,
                    label: `
                    ${L?.departure_airport_icao} / ${L?.departure_airport_iata} 
                    → ${L?.destination_airport_icao} / ${L?.destination_airport_iata}
                    `
                  }))}
                  onChange={L => {
                    handleChangeCostItemField('request_leg', L?.value);
                  }}
                />
              </div>
            </div>

            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Service Location </label>
                <Select
                  className='w-full small-top-margin small-bottom-margin'
                  value={newCostPlusListItems?.service_location
                    ? { value: newCostPlusListItems?.service_location, label: newCostPlusListItems?.service_location }
                    : null
                  }
                  options={legs &&
                    legs?.filter(L => L?.id == newCostPlusListItems?.request_leg)
                      ?.reduce((acc, L) => (
                        [
                          {
                            value: `${L?.departure_airport_iata} / ${L?.departure_airport_icao} `,
                            label: `${L?.departure_airport_iata} / ${L?.departure_airport_icao}`
                          },
                          {
                            value: `${L?.destination_airport_iata} / ${L?.destination_airport_icao}`,
                            label: `${L?.destination_airport_iata} / ${L?.destination_airport_icao}`
                          }
                        ]
                      ), [])
                  }
                  onChange={L => {
                    handleChangeCostItemField('service_location', L?.value);
                  }}
                />
              </div>
            </div>

            <div className='w3-col m12 s12 l12'>
              <div>
                <label> Select Services </label>
                <Select
                  isMulti={true}
                  closeMenuOnSelect={false}
                  className='w-full small-top-margin small-bottom-margin'
                  options={
                    FilteredRolesServices && FilteredRolesServices?.data?.map(S => ({
                      value: S?.id,
                      label: S?.name
                    }))}
                  onChange={selectedServices => {
                    handleSetCostItemsServices(selectedServices);
                  }}
                  value={FilteredRolesServices &&
                    FilteredRolesServices?.data?.filter(S => newCostPlusListItems?.services
                      ?.map(cost_service => cost_service?.service_id)?.indexOf(S?.id) > -1)
                      ?.map(S => (
                        {
                          value: S?.id,
                          label: S?.name
                        }
                      ))
                  }
                />
              </div>
            </div>

            <div className='w3-col m12 s12 l12'>
              <table className='w-full'>
                <tbody>
                  {newCostPlusListItems?.services &&
                    newCostPlusListItems?.services?.map((S, index) => (
                      <tr key={index}>
                        <td>
                          <div className='w3-medium cost-invoice-service-name'>
                            {S?.service_name}
                          </div>
                        </td>
                        <td>
                          <div className='w3-medium'>
                            <label> Quantity </label>
                            <input
                              type='number'
                              value={S?.quantity}
                              className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                              onChange={e => handleChangeCostItemServiceField(S?.temp_id, 'quantity', e.target.value)}
                              onBlur={e => handleChangeCostItemServiceField(S?.temp_id, 'quantity', Number(e.target.value))}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='w3-medium'>
                            <label> Unit Cost </label>
                            <input
                              type='number'
                              value={S?.unit_cost}
                              className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                              onChange={e => handleChangeCostItemServiceField(S?.temp_id, 'unit_cost', e.target.value)}
                              onBlur={e => handleChangeCostItemServiceField(S?.temp_id, 'unit_cost', Number(e.target.value)?.toFixed(2))}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='w3-medium'>
                            <label> VAT % </label>
                            <input
                              type='number'
                              value={S?.vat_percentage}
                              className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                              onChange={e => handleChangeCostItemServiceField(S?.temp_id, 'vat_percentage', e.target.value)}
                              onBlur={e => handleChangeCostItemServiceField(S?.temp_id, 'vat_percentage', Number(e.target.value)?.toFixed(2))}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className='py-1 px-2'>
            <div className="h-3"></div>
            <div className="flex justify-end">
              <button onClick={handleCloseCostListItemModal} className='w3-btn w3-grey w3-text-white'> Close </button>
              <button
                onClick={e => {
                  handleSaveCostListItem(newCostPlusListItems);
                  handleCloseCostListItemModal();
                }}
                disabled={
                  !newCostPlusListItems?.service_location ||
                  !Boolean(newCostPlusListItems?.services?.length)
                }
                className='w3-btn bg-primary-blue w3-text-white small-left-margin'
              >
                Confirm
              </button>
            </div>
            <div className="h-3"></div>
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default CostListItemModal;