// Packages
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";
import { auth } from '../../../atoms';
// APIs
import { NewAirportListRequest } from "../../../requests";

// Utils
import { constants, decodeString, ignoreTimeZone, authenticationErrorHandle } from "../../../utils";
import { TableLite, Edit, Plus, Refresh, SearchBar } from "../../../components";
import dayjs from 'dayjs';



function AirportsList() {
  const [isLoading, _isLoading] = useState(false);
  const Navigate = useNavigate();
  const SearchRef = useRef(null);
  const [searchString, _searchString] = useState('');
  const [airports, _airports] = useState([]);
  const [authState, _authState] = useAtom(auth);



  useEffect(() => {
    if (authState) {
      getAirports();
    }
  }, [authState])

  const getAirports = (page = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);
    NewAirportListRequest(token, page).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(
      data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data) {
          // Keep server data in local state with current time
          _airports({
            data: [...data?.map(airport => (
              {
                ...airport,
                //invoice_number: invoice?.invoice_number || '-',
                createdat: dayjs(ignoreTimeZone(airport?.createdat))?.format('YYYY-MM-DD')
              }
            ))],
            pagination: {
              currentPage: page,
              totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
            },
            created: Date.now()
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch cost plus invoices' });
        }
      )
  };

  const Airportsheaders = [
    "id",
    "airport_name",
    "country",
    "city",
    "airport_code_iata",
    "airport_code_icao",
    "latitude",
    "longitute",
    "type",
    "timezone",
  ];

  const CustomHeaders = { 'id': "Airport Id", 'airport_name': "Name", "airport_code_iata": "Iata", "airport_code_icao": "Icao" };

  const sortBy = ["id", "airport_name", "country", "airport_code_iata", "airport_code_icao"];

  // *********** Handlers ***********
  const routeToNewAirport = () => {
    Navigate("/airports/new");
  };

  const routeToEditAirport = (id) => {
    Navigate(`/airports/edit/${id}`);
  };

  const handleSearchString = (e) => {
    _searchString(e.target.value);
  };

  // *********** Render Functions ***********

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const NEW_AIRPORT_BUTTON = () => (
    <div className="flex w3-margin-bottom">
      <div className=" ml-auto">
        <div className="flex wrap">
          <button
            className="flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin"
          >
            <Refresh className="w-4 h-4" />{" "}
            <span className="px-1"> Refresh </span>
          </button>
          <button
            className="flex items-center w3-btn bg-primary-blue w3-text-white"
            onClick={routeToNewAirport}
          >
            <Plus className="w-5 h-5" />{" "}
            <span className="px-1"> New Airport </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTENT = () => (
    <div className="page-content w3-white h-full relative overflow-hidden">
      <div className="py-2">
        {NEW_AIRPORT_BUTTON()}
        <div className="list-view-container overflow-auto">
          <SearchBar
            placeholder='Search by Airport Id, Name, Country, Iata, Icao ...'
            containerClass='w-full relative'
            className='w3-input w3-medium'
            value={searchString}
            onChange={handleSearchString}
            SearchRef={SearchRef}
            buttonClass='cursor-pointer no-background w3-border-0 p-1 absolute right-0 small-right-margin'
          />
          <TableLite
            showActions={true}
            searchable={true}
            searchFormRef={SearchRef}
            data={(airports.data) || []}
            headers={Airportsheaders}
            customHeaders={CustomHeaders}
            sortBy={sortBy}
            searchBy={sortBy}
            actionTypes={["edit"]}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditAirport(row.id)}
            cellStyle={{ fontSize: "0.8em" }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  );
  return <div>{CONTENT()}</div>;
}

export default AirportsList;
