import { constants } from "../utils";

const ExportInvoicesToZatcaRequest = (data) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NjIzNjRlYjhiMTY0NDEyODM1MGI0ZDYiLCJmaXJzdF9uYW1lIjoiZmJvIiwibGFzdF9uYW1lIjoiaG9vayIsImVtYWlsIjoiZmJvaG9va0BzcGFlcm8uc2EiLCJpbnRlcm5hbF91c2VyIjp0cnVlLCJpYXQiOjE3MTUxMTA5NzcsImV4cCI6MzI5Mjk5MDk3N30.3q3tZWbHTteP9Q6b-jsaInr2-JVbwPRS5N6xcSXDeTs`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`https://spaicedev.spa.sa/hook/spaerolink-invoice/`, {
        method: 'POST',
        body: data,
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default ExportInvoicesToZatcaRequest;
