// Packages
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';

// APIs
import { ServiceListRequest, CostPlusInvoiceDetailsRequest, ApprovedFsrDetailsRequest } from '../../../requests';

// Utils
import { Save, TableLite, History } from '../../../components';
import { auth, servicesCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone, formatPrice } from '../../../utils';

function CostPlusInvoicesView() {
  const { costplusinvoiceId } = useParams();

  const INITIAL_FORM = {
    approve_request_id: '',
    contract_id: '',
    status: 'Draft',
    request_id: '',
    aircraft: null,
    cost_plus_invoice_item: []
  };

  const headers = ["service_location", "service_name", "quantity", "unit_cost", "total_cost", "vat_percentage"];

  const CustomHeaders = {
    "arrival_to_airport_iata": "Leg",
    "service_location": "Service At",
    "service_name": "Service",
    "unit_cost": "Unit Cost",
    "total_cost": "Total Cost",
    "vat_percentage": "VAT Amount",
    "vat_percentage": "VAT %",
  };

  const [isLoading, _isLoading] = useState(costplusinvoiceId ? true : false);
  const [parentRequest, _parentRequest] = useState(null);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [services, _services] = useAtom(servicesCache);

  // Fetch invoice details
  // Also fetch services either from cache or from server
  useEffect(() => {
    if (costplusinvoiceId && authState) {
      getCostPlusInvoiceDetails();
    }
    if (!services || !services.created || Date.now() - services.created >= 1200000) {
      getServices();
    }
  }, [authState, costplusinvoiceId]);

  // Fetch parent request of invoice
  useEffect(() => {
    if (Boolean(form?.approve_request_id)) {
      getApprovedFsrDetails();
    }
  }, [authState, form?.approve_request_id]);

  const getCostPlusInvoiceDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    CostPlusInvoiceDetailsRequest(token, costplusinvoiceId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        let cost_plus_invoice_item = data?.cost_plus_invoice_item || [];
        cost_plus_invoice_item = cost_plus_invoice_item?.map((item, index) => (
          {
            ...item,
            temp_id: `temp_${index}`,
            unit_cost: Number(item?.unit_cost).toFixed(3),
            total_cost: Number(item?.total_cost).toFixed(3),
            vat_amount: Number(item?.vat_amount).toFixed(3),
            vat_percentage: Number(item?.vat_percentage).toFixed(3),
            arrival_date_time: ignoreTimeZone(item?.arrival_date_time),
            departure_date_time: ignoreTimeZone(item?.departure_date_time)
          }
        ))?.sort((a, b) => {
          if (a.approve_request_leg === b.approve_request_leg) {
            return b.service_location?.localeCompare(a.service_location);
          }
          return a.approve_request_leg > b.approve_request_leg;
        });
        const newData = {
          ...data,
          cost_plus_invoice_item
        };
        _form(newData)
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch invoice details' });
        }
      )
  };

  const getApprovedFsrDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    ApprovedFsrDetailsRequest(token, form?.approve_request_id).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _parentRequest({ ...data });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      _isLoading(false);
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch request details" });
    });
  };

  const getServices = () => {
    const token = decodeString(authState);
    ServiceListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _services({
          data: [...data.results],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch services" });
    });
  };

  // *********** Handlers ***********

  const handleDownloadPdf = () => {
    const fileName = `invoice_${costplusinvoiceId}.pdf`;
    // create a wrapper div for content and set it's width
    const wrapper = document.createElement('div');
    wrapper.style.width = '1020px';
    wrapper.style.margin = '0px';
    // create a duplicate of the content div which is to be printed
    let sourceDiv = document.querySelector('.request-block');
    sourceDiv = sourceDiv.cloneNode(true);
    sourceDiv.style.padding = '0px';
    wrapper.appendChild(sourceDiv);
    let doc = new jsPDF('p', 'px', [650, 1500]);
    // create water mark
    for (let i = 1; i <= 2; i++) {
      doc.setPage(i)
      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.2, strokeOpacity: 1 }));
      doc.setTextColor('#1D1D1D40');
      // doc.text('DRAFT', 38, 15, { align: 'center', baseline: 'middle' })
      doc.restoreGraphicsState();
    }
    doc.html(wrapper, {
      callback: function (doc) {
        doc.save(fileName);
      },
      autoPaging: 'text',
      html2canvas: { scale: 0.6 },
      margin: [25, 0, 25, 0],
      x: 18,
      y: 0
    });
  };

  // *********** Render Functions ***********

  const RENDER_LEG_VALUE = {
    arrival_to_airport_iata: (item) => (
      <div>       
        <span className='flex items-center'>
          {`${item?.arriving_from_airport_icao} / ${item?.arriving_from_airport_iata}  `}
          <img src={'/right-arrow.png'} className='px-1' />
          {`  ${item?.arrival_to_airport_icao} / ${item?.arrival_to_airport_iata}`}
          {item?.description && item?.description?.length
            ? <span className='w3-tooltip cursor-pointer flex'>
              <span className="absolute left-0 tooltip-top w3-text w3-tag w-52 w3-small"> {item?.description} </span>
              <History className='small-left-margin w-6 h-6' />
            </span>
            : null
          }
        </span>
        {/* {form?.cost_plus_invoice_item[
          form?.cost_plus_invoice_item?.findIndex(costItem => costItem?.id === item?.id) + 1
        ]?.approve_request_leg !== item?.approve_request_leg
          ? <div className='absolute w3-border bottom-0 w-full w3-border-grey dashed'> </div>
          : <></>
        } */}
      </div>
    ),
    unit_cost: (item) => (<span> {formatPrice(Number(item?.unit_cost || 0))} </span>),
    total_cost: (item) => (<span> {formatPrice(Number(item?.total_cost || 0))} </span>),
    vat_percentage: (item) => (<span> {formatPrice(Number(item?.vat_percentage || 0))} </span>)
  };

  const INVOICE_DETAILS_SECTION = () => (
    <React.Fragment>
      <span className='table-heading'>
        <div>Invoice ID: {`${form?.id}`}</div>
      </span>
      <table className="custom-table">
        <tbody>
          <tr>
            <td className="title">Created Date</td>
            <td className="text">{dayjs(ignoreTimeZone(form?.createdat)).format('YYYY-MM-DD')}</td>
            <td colSpan={1} className="title">Updated Date</td>
            <td colSpan={1} className="text">{dayjs(ignoreTimeZone(form?.updatedat)).format('YYYY-MM-DD')}</td>
            <td className="title">Aircraft Registration</td>
            <td className="text">
              {
                form?.cost_plus_invoice_item?.find(item => item?.aircraft_tail_number)?.aircraft_tail_number
                || form?.aircraft?.tail_number
                || ''
              }
            </td>
          </tr>
          <tr>
            <td className="title">Invoice Type</td>
            <td className="text">{form?.invoice_type}</td>
            <td className="title">Request ID</td>
            <td className="text">
              <Link
                target='__blank'
                className='w3-text-blue flex items-center w-full text-center justify-center'
                to={form?.invoice_type === 'FSR'
                  ? `/requests/view/${form?.request_id}`
                  : `/hotel-reservations/view/${form?.request_id}`
                }
              >
                {form?.request_id}                
              </Link>
            </td>
            <td className="title"> Showing Items </td>
            <td className="text capitalize">
              {[...new Set(form?.allowed_roles?.map(item => item?.toLowerCase()))]
                ?.map((item, index) => <Fragment key={index}> {item} <br /> </Fragment>)}
            </td>
            {/* {
                (form?.cost_plus_invoice_item &&
                  form?.cost_plus_invoice_item?.find(item => item?.aircraft_tail_number)?.aircraft_tail_number
                ) || ''
              } */}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );

  const INVOICE_COST_ITEMS = () => (
    <React.Fragment>
      <span className='table-heading'>
        <div>Cost Items</div>
      </span>
      {parentRequest &&
      parentRequest?.approve_request_leg
      // ?.filter(approvedLeg => approvedLeg?.is_local)
      ?.map((approvedLeg) => (
        <div key={approvedLeg?.id}>
          <h3>
            <span className='flex items-center'>
              {`${approvedLeg?.departure_airport_icao} / ${approvedLeg?.departure_airport_iata}  `}
              <img src={'/right-arrow.png'} className='px-1' />
              {`  ${approvedLeg?.destination_airport_icao} / ${approvedLeg?.destination_airport_iata}`}             
            </span>
          </h3>
          <TableLite
            headers={headers}
            customHeaders={CustomHeaders}
            customRenderCell={RENDER_LEG_VALUE}
            rowClass={'relative'}
            data={form?.cost_plus_invoice_item?.filter(item => item?.approve_request_leg === approvedLeg?.id) || []}
          />
        </div>
      ))}      
      <table className='react-table-lite-table w3-border-bottom'>
        <tbody>
          <tr>
            <td className='w3-large bold'>
              <div className='flex justify-between px-1 py-2 text-primary-blue'>
                <span> Total: </span>
                {/* <span> {formatPrice(Number(form?.invoice_amount || 0))} </span> */}
                <span>
                  {
                    (() => {
                      let sum = 0;
                      form?.cost_plus_invoice_item.forEach((item) => {
                        sum += Number(item.total_cost) || 0;
                      });
                      return sum.toFixed(3);
                    })()
                  }
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='h-16'></div>
    </React.Fragment>
  );

  const DOWNLOAD_PDF_BUTTON = () => (
    <button
      onClick={handleDownloadPdf}
      className={`pdf-download-button fixed bottom-0 right-0 w3-btn bg-primary-blue w3-text-white shadow-lg w3-circle`}
    >
      <Save className='h-10 w-10' />
    </button>
  );

  return (
    <div id='Invoice-View'>
      <div className='request-block'>
        {INVOICE_DETAILS_SECTION()}
        {INVOICE_COST_ITEMS()}
        {DOWNLOAD_PDF_BUTTON()}
      </div>
    </div>
  )

}

export default CostPlusInvoicesView;







