import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import { CostPlusInvoiceListRequest, CostPlusInvoiceSearchRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone } from '../../../utils';
import { auth } from '../../../atoms';
import { TableLite, Edit, SearchBar, Plus } from '../../../components';

function CostPlusInvoiceList() {

  const Navigate = useNavigate();
  const SearchRef = useRef(null);
  const [isLoading, _isLoading] = useState(false);
  const [costPlusInvoices, _costPlusInvoices] = useState([]);  
  const [searchString, _searchString] = useState('');
  const [authState, _authState] = useAtom(auth);

  // Get users either from cache or from server
  useEffect(() => {
    if (authState) {
      getCostPlusInvoices();
    }
  }, [authState]);

  const getCostPlusInvoices = (page = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);
    CostPlusInvoiceListRequest(token, page).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(
      data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data?.results) {
          // Keep server data in local state with current time
          _costPlusInvoices({
            data: [...data?.results?.map(invoice => (
              {
                ...invoice,
                invoice_number: invoice?.invoice_number || '-',
                createdat: dayjs(ignoreTimeZone(invoice?.createdat))?.format('YYYY-MM-DD')
              }
            ))],
            pagination: {
              currentPage: page,
              paginationAvailable: 1,
              totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
            },
            created: Date.now()
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch cost plus invoices' });
        }
      )
  };

  const getSearchedCostPlusInvoices = () => {
    const token = decodeString(authState);
    _isLoading(true);
    CostPlusInvoiceSearchRequest(token, searchString).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(
      data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data) {          
          _costPlusInvoices({
            data: [...data?.map(invoice => (
              {
                ...invoice,
                invoice_number: invoice?.invoice_number || '-',
                createdat: dayjs(ignoreTimeZone(invoice?.createdat))?.format('YYYY-MM-DD')
              }
            ))],
            pagination: {
              currentPage: 1,
              paginationAvailable: 0,
              totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
            },
            created: Date.now()
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch cost plus invoices' });
        }
      )
  };

  const CostPlusInvoiceheaders = ["id", "invoice_type", "request_id", "createdat"];

  const CustomHeaders = {
    "id": "Invoice Id",
    "invoice_type": "Invoice Type",
    "request_id": "Request Id",
    "invoice_number": "Invoice No.",
    "createdat": "Created Date",
  };

  const sortBy = ["id", "request_id", "invoice_type"];

  // *********** Handlers ***********

  const handlePaginate = (pageNo) => {
    getCostPlusInvoices(pageNo);
  };

  const handleSearchString = (e) => {
    _searchString(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();   
    if(searchString?.trim() === ''){
      getCostPlusInvoices();
    } else{
      getSearchedCostPlusInvoices();
    }
  };

  const routeToEditCostPlusInoviceInvoice = (row) => {
    if (row?.invoice_type === 'FSR')
      Navigate(`/costplus-invoices/edit/fsr/${row?.id}`);
    else if (row?.invoice_type === 'HOTEL')
      Navigate(`/costplus-invoices/edit/hotel/${row?.id}`);
  };

  const routeToViewCostPlusInoviceInvoice = (id) => {
    Navigate(`/costplus-invoices/view/${id}`);
  };

  const routeToNewCostInvoice = () => {
    Navigate(`/costplus-invoices/new`);
  };

  // *********** Render Functions ***********

  const NEW_COST_INVOICE_BUTTON = () => (
    <div className="flex w3-margin-bottom">
      <div className=" ml-auto">
        <div className="flex wrap">          
          <button
            className="flex items-center w3-btn bg-primary-blue w3-text-white"
            onClick={routeToNewCostInvoice}
          >
            <Plus className="w-5 h-5" />{" "}
            <span className="px-1"> New Invoice </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_COST_INVOICE_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <SearchBar
            placeholder='Search by Invoice Id or Request Id...'
            containerClass='w-full relative'
            className='w3-input w3-medium'
            value={searchString}
            onChange={handleSearchString}                        
            onSearch={handleSearch}
            buttonClass='cursor-pointer no-background w3-border-0 p-1 absolute right-0 small-right-margin'
          />
          <TableLite
            showActions={true}
            data={costPlusInvoices && costPlusInvoices?.data || []}
            headers={CostPlusInvoiceheaders}
            customHeaders={CustomHeaders}
            actionTypes={['edit', 'view']}
            sortBy={sortBy}
            searchBy = {sortBy}
            searchable = {true}
            searchFormRef = {SearchRef}
            showPagination={costPlusInvoices?.pagination?.paginationAvailable}
            totalPages={costPlusInvoices?.pagination?.totalPages || 1}
            currentPage={costPlusInvoices?.pagination?.currentPage || 1}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditCostPlusInoviceInvoice(row)}
            onRowView={(event, row) => routeToViewCostPlusInoviceInvoice(row.id)}
            onPaginate={(pageNo) => handlePaginate(pageNo)}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default CostPlusInvoiceList;