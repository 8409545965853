import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function TypeHotelsInvoice({
    form,
    contracts,
    aircrafts,
    handleFieldChange,
    handleSetAircraft
}) {    
    return (
        <Fragment>
            <div className='w3-col l6 m6 s12'>
                <label className='w3-medium'> Hotel Request Id </label>
                <input
                    min={0}
                    type='number'
                    name="request_id"
                    value={form?.request_id}
                    onChange={handleFieldChange}
                    placeholder="Enter hotel request id..."
                    className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                />
            </div>
            <div className='w3-col l6 m6 s12'>
                <label className='w3-medium'> Aircraft </label>
                <input
                    disabled={true}
                    onChange={e => 0}
                    placeholder="Aircraft"
                    value={form?.aircraft_tail_number}
                    className='cursor-disable w3-input w3-border w3-round small-top-margin small-bottom-margin'
                />
            </div>
            {
                form?.request_id &&
                form?.aircraft_tail_number &&                
                <div className='w3-col l6 m6 s12 small-top-margin small-bottom-margin'>
                    <Link 
                        target="_blank"                        
                        to={`/hotel-reservations/view/${form?.request_id}`} 
                    >
                        <span className='w3-text-blue w3-hover-text-grey'> View Hotel Request-{form?.request_id} </span>
                    </Link>
                </div>
            }
        </Fragment>
    )
};