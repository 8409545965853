import { constants } from "../utils";

const HotelRequestSearchRequest = (token, string) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    const searchURL = `/api/hotelrequest/search/${string}/`;
    return (fetch(`${constants.BASEURL}${searchURL}`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default HotelRequestSearchRequest;
