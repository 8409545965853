// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { useAtom } from 'jotai';

// APIs
import { StandaloneservicesCostplusinvoiceEditRequest, AircraftByContractListRequest, ContractsListRequest, CostPlusInvoiceCreateRequest, FsrDetailsRequest, CostPlusInvoiceDetailsRequest, HotelRequestDetailsRequest } from '../../../requests';

// Utils
import { Loader } from '../../../components';
import { auth, contractsCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { TypeFsrInvoice, TypeHotelsInvoice } from './components';

function NewCostPlusInvoiceForm() {

  const Navigate = useNavigate();
  const { costplusinvoiceId } = useParams();

  const INITIAL_FORM = {
    invoice_type: 'HOTEL',  // 'HOTEL', 'Catering', 'MEDICAL_INSURANCE'
    request_id: '',
    contract_id: '',
    aircraft_id: '',
    aircraft_tail_number: '',
    invoice_amount: 0,
    items_to_create: [],
    cost_plus_invoice_item: []
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(costplusinvoiceId ? true : false);
  const [parentFsrRequest, _parentFsrRequest] = useState(null);
  const [parentHotelRequest, _parentHotelRequest] = useState(null);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [contracts, _contracts] = useAtom(contractsCache);
  const [aircrafts, _aircrafts] = useState([]);

  // Fetch airbases either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!contracts || !contracts.created || Date.now() - contracts.created >= 1200000) {
        getContracts()
      }
    }
  }, [authState]);

  // Fetch parent request of invoice
  useEffect(() => {
    if (Boolean(form?.request_id) && form?.invoice_type === 'FSR') {
      getFSRDetails();
    } else if (Boolean(form?.request_id) && form?.invoice_type === 'HOTEL') {
      // get parent hotel request with 800ms delay
      const getParentHotelData = setTimeout(() => {
        getHotelRequestDetails()
      }, 800);
      return () => clearTimeout(getParentHotelData);
    }
  }, [authState, form?.request_id]);

  // Fetch costplus invoice details for edit case
  useEffect(() => {
    if (parseInt(costplusinvoiceId)) {
      getCostPlusInvoiceDetails();
    }
  }, [authState, costplusinvoiceId]);


  // Get aircrafts based on contract
  useEffect(() => {
    if (form?.contract_id) {
      getAircraftsByContract();
    }
  }, [form?.contract_id, contracts?.data]);

  // Reset fields on invoice type change
  useEffect(() => {
    _form(old => ({
      ...old,
      request_id: '',
      contract_id: '',
      aircraft_id: '',
      aircraft_tail_number: '',
      invoice_amount: 0,
      items_to_create: [],
      cost_plus_invoice_item: []
    }));
  }, [form?.invoice_type])

  const getFSRDetails = () => {
    const token = decodeString(authState);
    FsrDetailsRequest(token, form?.request_id).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _parentFsrRequest({ ...data });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch request details" });
    });
  };

  const getHotelRequestDetails = () => {
    const token = decodeString(authState);
    HotelRequestDetailsRequest(token, form?.request_id).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (data && data?.detail) Store.addNotification({ ...constants.ERRORTOAST, message: data?.detail });
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _parentHotelRequest(data);
        _form(old => ({
          ...old,
          aircraft_tail_number: data?.ACFT_REG,
          aircraft_id: data?.aircraft,
        }));
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch hotel reservation details' });
        }
      )
  };

  const getCostPlusInvoiceDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    CostPlusInvoiceDetailsRequest(token, costplusinvoiceId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _form(old => ({
          ...old,
          ...data,
          aircraft_id: data?.cost_plus_invoice_item?.[0].aircraft_id,
          aircraft_tail_number: data?.cost_plus_invoice_item?.[0].aircraft_tail_number
        }))
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch invoice details' });
        }
      )
  };

  const getContracts = () => {
    const token = decodeString(authState);
    ContractsListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _contracts({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({
          ...constants.ERRORTOAST,
          message: "Failed to fetch contracts",
        });
      });
  };

  const getAircraftsByContract = () => {
    const token = decodeString(authState);
    AircraftByContractListRequest(token, form?.contract_id).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        nonPaginatedData.forEach(A => A.selected = false);
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  // *********** Handlers ***********

  const handleFieldChange = (e) => {
    let resetAircraft = {};
    let cost_plus_invoice_item = [...form?.cost_plus_invoice_item];
    if (e.target.name === 'contract_id')
      resetAircraft = { aircraft_id: '', aircraft_tail_number: '' }
    else if (e.target.name === 'invoice_type')
      cost_plus_invoice_item = [];
    _form(old => ({
      ...old,
      ...resetAircraft,
      cost_plus_invoice_item,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSetAircraft = (e) => {
    _form(old => ({
      ...old,
      [e.target.name]: e.target.value,
      aircraft_tail_number: aircrafts?.data?.find(A => A?.id == e.target.value)?.tailnumber
    }));
  };

  const handleSelectFsr = (fsr) => {
    _form(old => ({
      ...old,
      ...fsr
    }))
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = {};
    data.cost_plus_invoice = {
      contract_id: parseInt(form?.contract_id),
      invoice_amount: form?.invoice_amount,
      invoice_type: form?.invoice_type,
      request_id: form?.request_id
    };
    if (form.invoice_type == 'HOTEL')
      data.cost_plus_invoice_item = [
        {
          total_cost: form?.invoice_amount,
          service_name: form?.invoice_type,
          aircraft_id: parseInt(form?.aircraft_id),
          aircraft_tail_number: form?.aircraft_tail_number,
          quantity: parentHotelRequest?.crew_members?.length
        }
      ];
    _isSubmitting(true);    
    if (parseInt(costplusinvoiceId)) {
      // Edit case.      
      data.cost_plus_invoice.id = Number(costplusinvoiceId);
      let editted_cost_invoice_item = form?.cost_plus_invoice_item?.[0] || {};
      editted_cost_invoice_item = {
        ...editted_cost_invoice_item,
        service_name: form?.invoice_type,
        total_cost: form?.invoice_amount,
        aircraft_id: parseInt(form?.aircraft_id),
        aircraft_tail_number: form?.aircraft_tail_number,
        quantity: parentHotelRequest?.crew_members?.length
      };
      data.cost_plus_invoice_item = [editted_cost_invoice_item];
      StandaloneservicesCostplusinvoiceEditRequest(token, JSON.stringify(data), costplusinvoiceId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Invoice updated' });
            Navigate("/costplus-invoices");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
    else {
      // Create case
      CostPlusInvoiceCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Invoice created' });
            Navigate("/costplus-invoices");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  const handleCostListItemFieldChange = (e, serviceItem) => {
    var updatedForm = { ...form };
    updatedForm?.cost_plus_invoice_item?.forEach(C => {
      let changedServices = [...C?.services]?.map(S => {
        if (S?.temp_id === serviceItem?.temp_id) {
          S[e.target.name] = e.target.value;
        }
        return S;
      });
      C.services = changedServices;
    });
    _form({ ...updatedForm });
  };

  const handleSaveCostListItem = (costInvoiceItem) => {    
    _form(old => (
      {
        ...old,
        cost_plus_invoice_item: [...old?.cost_plus_invoice_item, costInvoiceItem]
      }
    ));
  };

  // *********** Render Functions ***********

  const COST_PLUS_INVOICE_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col s12'>
          <label className='w3-medium'> Invoice For </label>
          <select
            name="invoice_type"
            value={form?.invoice_type}
            onChange={handleFieldChange}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={'HOTEL'}> Hotel </option>
            {/* <option value={'FSR'}> FSR </option> */}
            {/* <option value={'Catering'}> Catering </option>
            <option value={'MEDICAL_INSURANCE'}> Insurance </option> 
            */}
          </select>
        </div>
        {form?.invoice_type == 'HOTEL' &&
          <TypeHotelsInvoice
            form={form}
            aircrafts={aircrafts}
            contracts={contracts}
            handleSetAircraft={handleSetAircraft}
            handleFieldChange={handleFieldChange}
          />
        }
        {form?.invoice_type == 'FSR' &&
          <TypeFsrInvoice
            form={form}
            aircrafts={aircrafts}
            contracts={contracts}
            parentFsrRequest={parentFsrRequest}
            handleSelectFsr={handleSelectFsr}
            handleSetAircraft={handleSetAircraft}
            handleFieldChange={handleFieldChange}
            handleSaveCostListItem={handleSaveCostListItem}
            handleCostListItemFieldChange={handleCostListItemFieldChange}
          />
        }
        {/*
        {form?.invoice_type == 'Catering' &&
          <></>
        }
        {form?.invoice_type == 'MEDICAL_INSURANCE' &&
          <></>
        }
        */}
      </div>
    </React.Fragment>
  );

  const COST_PLUS_TOTAL_SECTION = (item = {}) => (
    <div className='request-form-container w-half relative py-2' style={{ float: 'right', width: '30%' }}>
      <span className='heading'> Cost Plus Invoice Total</span>
      <div className='border-primary-blue w3-round p-2'>
        <div className='flex py-2'>
          <div className='w-full'>
            <label> Total Cost </label>
            <input
              type='number'
              name="invoice_amount"
              onChange={handleFieldChange}
              className='w3-input w3-border w3-round'
              value={form?.invoice_amount}
            />
          </div>
        </div>
      </div>
    </div>
  )

  const CONTENT = () => (
    <React.Fragment>
      <div className='request-form-container w-full relative'>
        <span className='heading'> Cost Plus Invoice </span>
        <div className='border-primary-blue w3-round p-2'>
          {COST_PLUS_INVOICE_DETAILS()}
        </div>
      </div>
      {COST_PLUS_TOTAL_SECTION()}
      {FORM_FOOTER()}
    </React.Fragment>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={!form?.aircraft_id || !form?.request_id || isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  return (
    <div id='Invoice-Form'>
      {isLoading
        ? LOADER()
        : CONTENT()
      }
    </div>
  )

}

export default NewCostPlusInvoiceForm;
